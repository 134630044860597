<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <b-form-group description="Цех" class="col-4">
                    <storage-picker :nullable="false" v-model="filters.storage"></storage-picker>
                </b-form-group>
                <b-form-group class="col-8">
                    <actual-shift-picker :disabled="!filters.storage" :storage="filters.storage" v-model="filters.actualShift"></actual-shift-picker>
                </b-form-group>
            </div>
        </div>
        <div class="card-body" style="padding:0 !important;">
            <table class="table table-sm table-bordered">
                <thead class="thead-dark">
                <tr>
                    <th colspan="4">Выпуск</th>
                </tr>
                <tr>
                    <th>Номенклатура</th>
                    <th class="text-right">План выпуска</th>
                    <th class="text-right">Факт выпуска</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="3">
                        <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                    </td>
                </tr>
                <tr v-else-if="production.length === 0">
                    <td colspan="3">
                        <div class="alert alert-warning text-center">Нет данных</div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="item in production" :key="item.id" :class="{'alert-danger': hasProductionTrouble(item)}">
                        <td>{{item.nomenclature.name}}</td>
                        <td class="text-right">{{item.plan.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                        <td class="text-right">{{item.fact.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                    </tr>
                </template>
                </tbody>
            </table>
            <table class="table table-sm table-bordered mb-0">
                <thead class="thead-dark">
                <tr>
                    <th colspan="5">Расход</th>
                </tr>
                <tr>
                    <th>Номенклатура</th>
                    <th class="text-right">Поступления</th>
                    <th class="text-right">План расхода</th>
                    <th class="text-right">Факт расхода</th>
                    <th class="text-right">Остаток</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="5">
                        <div class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i> Загрузка...</div>
                    </td>
                </tr>
                <tr v-else-if="usage.length === 0">
                    <td colspan="5">
                        <div class="alert alert-warning text-center">Нет данных</div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-for="item in usage"
                        :key="item.id"
                        @click="showStorageMovements(item)"
                        :class="{ 'alert-danger': hasUsageTrouble(item), 'active-row': true }"
                    >
                        <td>{{item.nomenclature.name}}</td>
                        <td class="text-right">{{item.incoming.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                        <td class="text-right">{{item.plan.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                        <td class="text-right">{{item.fact.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                        <td class="text-right">{{item.remain.toFixed(3)}} {{item.nomenclature.measureUnit.name}}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import StoragePicker from "../_common/StoragePicker";
    import ActualShiftPicker from "../_common/ActualShiftPicker";
    import lsfMixin from "../../utils/localStorageFilterMixin";
    import {STORAGE_MOVEMENT_ENDPOINT} from "../../utils/endpoints";

    const FILTER_PATTERN = {
        storage: null,
        actualShift: null
    };

    export default {
        name: "StuffUsageReport",
        mixins: [lsfMixin],
        components: {StoragePicker, ActualShiftPicker},
        watch: {
            filters: {
                handler: function (value) {
                    this.watchFilters(value)
                },
                deep: true
            }
        },
        data() {
            return {
                filters: {
                    storage: null,
                    actualShift: null,
                },
                loading: false,
                usage: [],
                production: [],
            };
        },
        methods: {
            applyFilters() {
                if (!this.filters.storage || !this.filters.actualShift) {
                    return;
                }

                this.loading = true;
                this.$http.get(STORAGE_MOVEMENT_ENDPOINT + '/stuff-usage-report', {
                    params: {
                        storage_id: this.filters.storage.id,
                        actual_shift_id: this.filters.actualShift.id,

                        without_loading: true
                    },
                    before( request ) {
                        // abort previous request, if exists
                        if ( this.previousRequest ) {
                            this.previousRequest.abort();
                        }

                        // set previous request on Vue instance
                        this.previousRequest = request;
                    }
                }).then(response => {
                    this.usage = response.data.usage;
                    this.production = response.data.production;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else if (response.status !== 0) {
                        this.$toast.error('Ошибка');
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            showStorageMovements(item) {
                this.$modal.show('storage-movements', {
                    storage: this.filters.storage,
                    actualShift: this.filters.actualShift,
                    nomenclature: item.nomenclature
                });
            },
            hasUsageTrouble(item) {
                return Math.abs(item.incoming - item.fact - item.remain) > 0.005;
            },
            hasProductionTrouble(item) {
                return item.plan > item.fact;
            }
        },
        created() {
            this.initFilters(this, FILTER_PATTERN);
        }
    }
</script>

<style scoped>
    tr.active-row {
        cursor: pointer;
    }

    tr.active-row:hover td {
        box-shadow: inset 0px 0px 100px 100px rgba(0, 0, 0, .05);
    }
</style>
